import ApplicationLogo from "../common/components/elements/ApplicationLogo/ApplicationLogo";
import AuthCard from "../common/components/elements/AuthCard/AuthCard";
import AuthSessionStatus from "../common/components/elements/AuthSessionStatus/AuthSessionStatus";
import AuthValidationErrors from "../common/components/elements/AuthValidationErrors/AuthValidationErrors";
import Button from "../common/components/elements/Button/Button";
import GuestLayout from "../common/components/elements/Layout/GuestLayout";
import Input from "../common/components/elements/Input/Input";
import Label from "../common/components/elements/Label/Label";
import Link from "next/link";
import { useAuth } from "../common/hooks/auth";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import en from "../../public/static/locales/en/common.json";
import ar from "../../public/static/locales/ar/common.json";
import logo from "../assets/images/amt-logo.png";

const Login = () => {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const { login } = useAuth({
    middleware: "guest",
    userRedirectIfAuthenticated: process.env.NEXT_PUBLIC_FRONTEND_URL,
    adminRedirectIfAuthenticated: "/admin/dashboard",
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (router.query.reset?.length > 0 && errors.length === 0) {
      setStatus(atob(router.query.reset));
    } else {
      setStatus(null);
    }
  });

  const submitForm = async (event) => {
    event.preventDefault();

    login({ email, password, setErrors, setStatus });
  };

  return (
    <GuestLayout>
      <AuthCard
        logo={
          <Link href="/">
            <a>
              <ApplicationLogo
                src={logo}
                className="block h-10 w-auto fill-current text-gray-600"
                width={80}
                height={30}
              />
            </a>
          </Link>
        }
      >
        {/* Session Status */}
        <AuthSessionStatus className="mb-4" status={status} t={t} />

        {/* Validation Errors */}
        <AuthValidationErrors className="mb-4" errors={errors} t={t} />

        <form onSubmit={submitForm}>
          {/* Email Address */}
          <div>
            <Label htmlFor="email">{t.Email}</Label>

            <Input
              id="email"
              type="email"
              value={email}
              className="block mt-1 w-full"
              onChange={(event) => setEmail(event.target.value)}
              required
              autoFocus
            />
          </div>

          {/* Password */}
          <div className="mt-4">
            <Label htmlFor="password">{t.Password}</Label>

            <Input
              id="password"
              type="password"
              value={password}
              className="block mt-1 w-full"
              onChange={(event) => setPassword(event.target.value)}
              required
              autoComplete="current-password"
            />
          </div>

          {/* Remember Me */}
          <div className="block mt-4">
            <label htmlFor="remember_me" className="inline-flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                name="remember"
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />

              <span className="ml-2 text-sm text-gray-600">{t.RememberMe}</span>
            </label>
          </div>

          <div className="flex items-center justify-end mt-4">
            <Link href="/forgot-password">
              <a className="underline text-sm text-gray-600 hover:text-gray-900">
                {t.ForgotPassword}
              </a>
            </Link>

            <Button className="ml-3 btn-red py-2">{t.Login}</Button>
          </div>
        </form>
      </AuthCard>
    </GuestLayout>
  );
};

export default Login;
