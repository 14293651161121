const AuthCard = ({ logo, children }) => (
  <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
    <div>{logo}</div>

    <div className="w-[400px] bg-white sm:max-w-md mt-6 px-6 py-4 shadow-xl overflow-hidden sm:rounded-lg">
      {children}
    </div>
  </div>
);

export default AuthCard;
